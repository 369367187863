import React, { useCallback } from 'react';
import { graphql } from 'gatsby';
import { Container, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useColorMode } from '@context/ColorModeContext';
import { SET_COLOR_MODE } from '@context/ColorModeContext/actions';
import { EntryContextProvider } from '@context/EntryContext';
import Layout from '@c/Layout';
import VisibilitySensor from '@c/VisibilitySensor';
import FeaturedContentGrid from '@c/FeaturedContentGrid';
import Pagination from '@c/Pagination';
import ArticleContent from '@c/ArticleContent';

const OurIdeas = ({ data, pageContext: { currentPage, numPages }, ...props }) => {
  const theme = useTheme(),
    [, setBackground] = useColorMode(),
    title = data.cms.ourIdeas.title,
    intro = `<h1>${title}</h1>${data.cms.ourIdeas.text}`,
    articleContent = data.cms.ourIdeas.articleContent,
    entries = data.cms.ideas,
    seomatic = data.cms.seomatic,
    ctaUrl = `/${data.cms.ourIdeas.uri}`;

  const handleOnVisible = useCallback(
    id => {
      setBackground({
        type: SET_COLOR_MODE,
        background: { color: theme.colors.bg },
        header: { mode: 'light' },
        visibility: { visibleContentId: id },
      });
    },
    [setBackground, theme.colors.bg]
  );

  return (
    <EntryContextProvider entry={data.cms.ourIdeas}>
      <Layout seomatic={seomatic}>
        <VisibilitySensor id={`cases-${currentPage}`} onVisible={handleOnVisible}>
          <Box mt={{ xs: 12, md: 19.5 }}>
            <Container>
              <FeaturedContentGrid ctaUrl={ctaUrl} intro={intro} entries={entries} />

              {numPages > 1 && (
                <Box mt={{ xs: 12, md: 32 }}>
                  <Pagination pagePrefix={ctaUrl} currentPage={currentPage} pageCount={numPages} />
                </Box>
              )}
            </Container>
          </Box>
        </VisibilitySensor>

        <ArticleContent content={articleContent} mb={{ xs: 19.5, md: 32 }} />
      </Layout>
    </EntryContextProvider>
  );
};

export default OurIdeas;

export const query = graphql`
  query($limit: Int!, $skip: Int!, $path: String!) {
    cms {
      ourIdeas: entry(section: "ourIdeas") {
        uri
        ... on cms_ourIdeas_ourIdeas_Entry {
          title
          text
          articleContent {
            ...articleContentFields
          }
        }
      }
      ideas: entries(section: "ideas", limit: $limit, offset: $skip) {
        ...ideasBaseFields
      }
      seomatic(uri: $path) {
        ...seomaticFields
      }
    }
  }
`;
